import axios from 'axios'

const API = '/visitas'

export function ObtenerVisitantes(params){
    return axios.get(`${API}`, {params})
}

export function ObtenerVisitante(params){
    return axios.get(`${API}/detalle`, {params})
}

export function ActualizarVisitante(params){
    return axios.put(`${API}/editar`, params, {headers: {_method: 'put'}})
}

export function ObtenerHistorialVisitantes(params){
    return axios.get(`${API}/historial`, {params})
}

export function ObtenerHistorialVisitante(params){
    return axios.get(`${API}/historial-detalle`, {params})
}

export function exportarExcelVisitas(params){
    return axios.get(`${API}/exportar`, {params})
}