<template>
    <modal ref="modalDetalleVisitante" titulo="Visitante" :btn-cancelar="false" width="30%">
        <div class="px-3">
            <div class="d-middle flex-column mb-3">
                <el-image style="width: 100px; height: 100px" :src="img" :zoom-rate="1.2" :preview-src-list="[img]" :initial-index="4" fit="cover" class="wh-70 rounded-circle mb-2" @error="img = '/img/no-imagen/admin.svg'" />
                <div class="d-middle gap-2">
                    <p class="f-medium f-15 text-black">{{ nombre }}</p>
                    <i v-if="editable" class="icon-pencil-outline cr-pointer" @click="$emit('editar')" />
                </div>
                <span class="f-light f-14 text-86">CC. {{ documento }}</span>
                <span class="f-light f-14 text-86">Total de ingresos: {{ totalIngresos }}</span>
            </div>
            <slot name="body" />
            <div v-if="ingresado" class="mb-3 my-2">
                <label class="f-14 f-medium text-black">Registrado por:</label>
                <p class="f-light f-14 text-86">{{ ingresado }}</p>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        imagen: {
            type: String,
            default: ''
        },
        nombre: {
            type: String,
            default: ''
        },
        documento: {
            type: String,
            default: ''
        },
        totalIngresos: {
            type: Number,
            default: 0
        },
        editable: {
            type: Boolean,
            default: false
        },
        ingresado: {
            type: String,
            default: ''
        },
    },
    data(){
        return {
            img: ''
        }
    },
    methods:{
        async toggle(){
            this.img = this.imagen ? this.imagen : ''
            this.$refs.modalDetalleVisitante.toggle();
        }
    }
}

</script>

<style lang="scss" scoped>
</style>